import React from 'react';

// === Components === //
import { Link } from 'gatsby';

// === Helpers === //
import cn from 'classnames';
import * as utils from '@utils';

// === Styles === //
import * as styles from '@components/ThankYou/ThankYou.module.scss';

const ThankYou = () => {
  return (
    <div className={cn(styles.thankYouWrapper, 'form-wrapper')}>
      <div className={styles.thankYouInner}>
        <h1 className={styles.thankYouHeadline}>
          Thank you,<br></br>you just connected threads ;)
        </h1>
        <p className={styles.thankYouDescription}>
          Fill free to make{' '}
          <Link to="/add-your-photo" className={styles.thankYouLink}>
            another submission
          </Link>{' '}
          or<br></br>go{' '}
          <Link to="/" className={styles.thankYouLink}>
            back to homepage
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
